import moment from 'moment';
import { actions } from "../../../components/grid-views/standart-actions-without-view";
import { statusActions } from "../../../components/grid-views/status-actions";

export const columns = [
  {
    label: 'preview',
    field: 'preview',
    width: '60%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'date',
    field: 'dateStart',
    width: '30%',
    formatRowValue: true,
    formatFn: (value) => {
      return value.dateStart ? moment(value.dateStart).format('DD.MM.YYYY') : '';
    },
    filterOptions: {
      enabled: true,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...statusActions,
      ...actions,
    ]
  },
]
