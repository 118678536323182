import {validators} from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "title",
      required: true,
      placeholder: "name",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t('nameRequired'),
          textTooBig: i18n.t('nameMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "url",
      model: "url",
      required: true,
      placeholder: "url",
      max: 256,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t('urlRequired'),
          textTooBig: i18n.t('urlMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "editor",
      label: "preview",
      model: "preview",
      required: true,
      placeholder: "preview",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("preview"),
        }),
      ]
    },
    {
      type: "editor",
      label: "text",
      model: "text",
      required: true,
      placeholder: "text",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("text"),
        }),
      ]
    },
    {
      type: "datepicker",
      label: "dateStart",
      model: "dateStart",
      required: false,
      placeholder: "dateStart",
      validator: [
        validators.date.locale({
          invalidDate: i18n.t("invalidDate"),
        }),
      ]
    },
    {
      type: "datepicker",
      label: "dateEnd",
      model: "dateEnd",
      required: false,
      placeholder: "dateEnd",
      validator: [
        validators.date.locale({
          invalidDate: i18n.t("invalidDate"),
        }),
      ]
    },
    {
      type: "checkbox",
      label: "isImportant",
      model: "isImportant",
      required: true,
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      selectOptions: {
        noneSelectedText: 'chooseStatus',
      },
      values: [
        { name: i18n.t("activeStatus"), id: 1 },
        { name: i18n.t("hiddenStatus"), id: 0 },
      ],
    },
  ]
};
